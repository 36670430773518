import {useEffect} from 'react';
import {getCDP} from '@modules/logCenter/cdp';
import {cdpStrategy, recSender} from '@modules/logCenter';
import {useLegacyUser} from '@deezer/react-user';

export const useInitTracking = () => {
	const [legacyUser] = useLegacyUser();

	useEffect(() => {
		if (!legacyUser?.USER?.USER_ID) return;
		getCDP().init();
		getCDP().identify({userId: legacyUser?.USER?.USER_ID.toString()});
		cdpStrategy.openGate();
	}, [legacyUser?.USER?.USER_ID]);

	useEffect(() => {
		if (!legacyUser?.USER_TOKEN) return;
		recSender.setAuthToken(legacyUser?.USER_TOKEN ?? '');
	}, [legacyUser?.USER_TOKEN]);
};
