import {getCDP} from '@modules/logCenter/cdp';
import {config} from '@modules/config';
import LogCenter, {
	GateStrategy,
	CDPSender,
	MemoryStorage,
	RECSender,
	getBatchConfiguration,
	BatchStrategy,
} from '@deezer/logcenter';
import {LogType} from './types';

const memoryStorage = new MemoryStorage();

const batchConfig = {
	...getBatchConfiguration({
		maxBatchSize: 10,
		autoSendInterval: 10 * 1000,
	}),
};

/**
 * REC strategy
 */

const recConfig = {
	...batchConfig,
	handledLogTypes: [LogType.Gauge],
};

export const recSender = new RECSender(
	`https://${config.get('recHost')}/1.0/events/`,
	'',
	batchConfig,
);

export const recStrategy = new BatchStrategy(
	recSender,
	memoryStorage,
	recConfig,
);

/**
 * CDP strategy
 */

const cdpConfig = {
	handledLogTypes: [LogType.CDP],
};

export const cdpSender = new CDPSender(getCDP());

export const cdpStrategy = new GateStrategy(
	cdpSender,
	memoryStorage,
	cdpConfig,
);

/**
 * Logcenter
 */

export const logCenterInstance = new LogCenter(
	[recStrategy, cdpStrategy],
	memoryStorage,
);

export const logCenter = {
	log: logCenterInstance.log.bind(logCenterInstance),
	flush: recStrategy.flush.bind(recStrategy),
};
